import React, { Fragment, PureComponent } from 'react'
import {
  Layout
} from '../../components'
import '../style.less'

class CookiePolicy extends PureComponent {
  componentDidMount () {
    document.body.scrollTop = 0
  }

  render () {
    return (
      <Fragment>
        <Layout
          title={'Cookie Policy'}
          location={this.props.location}
        >
          <div className='container cookie-policy'>
            <div className='row'>
              <div className='col-xs-12 col-md-10 col-md-offset-1 panel'>
                <br />
                <h1>Cookie Policy</h1>
                <h2>What are cookies?</h2>
                <p>Most websites need to collect basic information on their users to work properly. To do this, websites - including this one - create small text files known as cookies on their users&apos; devices. </p>

                <p>There are a lot of different cookies which do a lot of different jobs. They can be used to remember your preferences on sites you visit often, to remember which products are in your online shopping baskets, and to make sure that the online adverts that you see are relevant to you.</p>

                <p>Most of the data that cookies collect is anonymous. However, some of it is designed to detect patterns in site usage, user behaviour and approximate location to improve the experience of our users.</p>

                <p>Information collected by cookies is not personally identifiable.</p>

                <p>The cookies used by us can be categorised as essential, performance, advertising or functional. You can find out more about the cookies we use and why we use them below.</p>
                <h2>Performance cookies</h2>

                <table className='table table-bordered table-condensed'>
                  <caption >These help us understand how people are using the our website, so we can understand where and how we can improve. They also allow us to test new ideas with to enhance your site experience.</caption>
                  <tbody><tr>
                    <th >Cookie</th>
                    <td>
                      What it&apos;s for
                    </td>
                  </tr>
                  <tr>
                    <th>Google</th>
                    <td>
                      <p>We use Google products, including but not only Google Analytics, Tag Manager and Optimise. None of these cookies will ever store or use personally-identifiable information about a user. Google mainly uses first-party cookies to report on user interactions.</p>
                      <p>To opt out of Google Analytics, please visit <a href='https://tools.google.com/dlpage/gaoptout' target='_blank' rel='noreferrer' title='Opt out of Google cookies'>this link</a>.</p>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <h2>How to control and delete cookies</h2>
                <p>To delete any cookies stored in your browser go to the help and support area on your internet browser for instructions.</p>
                <p>Information on deleting or controlling cookies is also available at <a href='https://www.allaboutcookies.org' target='_blank' rel='noreferrer' title='All about Cookies'>allaboutcookies.org</a></p>

              </div>
            </div>
          </div>
        </Layout>
      </Fragment>
    )
  }
}

export default CookiePolicy
